$bg-color-1: rgb(43, 43, 43);
$bg-color-2: rgb(37, 37, 37);

.main-display {
	height: calc(110vh);
	@media (orientation: landscape) {
		background-image: url("./main-display-background-landscape.png");
	}

	@media (orientation: portrait) {
		background-image: url("./main-display-background-portrait.png");
	}
	background-color: $bg-color-1;
	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	.inner-display {
		background-color: rgba($color: rgb(25, 25, 25), $alpha: 0.95);
		border-radius: 25px;
		margin-top: -5vh;
		h1 {
			margin-bottom: 6rem !important;
		}
	}

	box-shadow: inset 0 0 5rem rgba(0, 0, 0, 0.5);
}
