footer {
    padding: 1rem 0 1rem 0;
    background-color: rgb(20, 20, 20);
    border-top: var(--bs-border-width) var(--bs-border-style) var(--bs-border-color);

    div.container {
        justify-content: space-between;
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;
        align-items: center;

        div.footer-left {
            display: flex;
            align-items: center;
        }

        div.footer-right {
            display: flex;
            justify-content: end;
            padding-left: 0;
            list-style: none;

            a:not(:last-child) {
                margin-right: 1em;
            }
        }
    }
}