$bg-color-1: rgb(43, 43, 43);
$bg-color-2: rgb(37, 37, 37);

div.categories {
	.category-display {
		min-height: 110vh;
		padding: 7vh 0vh 5vh 0vh;
		box-shadow: inset 0 0 2rem rgba(0, 0, 0, 0.2);
		.lead-paragraph {
			padding: 2vh 0vh;
		}
	}

	div.category-display:nth-of-type(odd) {
		background-color: $bg-color-2;
	}
	div.category-display:nth-of-type(even) {
		background-color: $bg-color-1;
	}
}

.topic-carousel .carousel-item .container {
	width: 70%;
}
